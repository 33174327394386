@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/Helvetica.ttf");
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  background: #051368 !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

.custom-container {
  width: 100%;
  padding-right: "2rem";
  padding-left: "2rem";
  margin-right: auto;
  margin-left: auto;
}

.allow_list_title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0%;
}

.allow_list_p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
}

.crossmint_note {
  color: #FFFFFF;
  opacity: 0.5;
  font-weight: 400;
}

a {
  text-decoration: none !important;
}

.pt-5rem {
  padding-top: 4rem;
}

#error {
  color: #F7276B;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

#error_msg {
  color: #F7276B;
  font-size: 1.125rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 0%;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.learn-more {
  border: 1px solid #fff;
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: transparent;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Helvetica";
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.learn-more:hover {
  color: #fff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.model-learn-more {
  border: 1px solid #fff;
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: transparent;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Helvetica";
}

.model-learn-more:hover {
  color: #fff;
}

.token-id {
  background: #fff;
  border: 1px solid #F7276B;
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  width: 40%;
}

.token-id:focus {
  outline: none;
}

.token-id-btn {
  width: 58%;
}

.theme-btn {
  background: #F7276B;
  border-radius: 0.5rem;
  color: #fff;
  padding: 0.75rem 1.25rem;
  font-family: "Helvetica";
  font-size: 1rem;
  font-weight: 600;
  border: none;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.theme-btn:hover {
  color: #fff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.load-more-btn {
  background: transparent;
  border-radius: 0.5rem;
  color: #F7276B;
  padding: 0.75rem 1.25rem;
  font-family: "Helvetica";
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid #F7276B;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.load-more-btn:hover {
  color: #F7276B;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.navbar {
  background: #091d2c;
}

.navbar .navbar-nav {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navbar .nav-link {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #fff !important;
  margin: 0 1rem;
  font-size: 1rem;
}

.navbar .nav-link:hover {
  color: #F7276B !important;
}

.navbar .dropdown-menu {
  margin-top: 100px;
  width: 100%;
  background: #081d2c;
}

.navbar .dropdown-menu a {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.navbar .dropdown-menu a:hover {
  color: #F7276B;
  background: #081d2c;
}

.navbar .dropdown-toggle {
  margin: 0%;
}

.navbar .dropdown-toggle span {
  opacity: 0.6;
}

.navbar .dropdown-toggle::after {
  content: none;
}

.desc-card {
  padding: 1rem;
  background: #030D49;
  border-radius: 15px;
}

.desc-card h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  color: #BDB9BA;
}

.desc-card h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 1rem;
  margin-bottom: 0%;
}

.credits .home-title {
  font-size: 3rem !important;
}

.credits .subtitle {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.homepage .desc-lifetime-founder {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: justify;
}

.homepage .opensea {
  color: #fff;
  text-decoration: underline !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.375rem;
}

.homepage .title {
  font-size: 2rem;
  margin-bottom: 0%;
}

.homepage .remaining {
  font-family: "Montserrat", sans-serif;
  color: #F7276B;
  font-size: 2.25rem;
  font-weight: 400;
}

.homepage .total {
  font-size: 1.25rem;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}

.homepage .light-text {
  color: #E5E5E5;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 13px;
}

.homepage .description {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 1.375rem;
}

.mint .remaining {
  font-family: "Montserrat", sans-serif;
  font-size: 1.75rem;
}

.mint .title {
  font-size: 3rem;
}

.mint .desc {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.mint .convertion-card {
  background: transparent;
  border: none;
}

.mint .convertion-card .btn {
  background-color: #F7276B;
  color: #fff;
  padding: 1rem;
  border: 1px solid #F7276B;
  font-size: 0.95rem;
  font-weight: 600;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.mint .convertion-card .btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.mint .convertion-card .btn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.mint .convertion-card .card-body .buy-with-card .input-group {
  width: 50%;
}

.mint .convertion-card .card-body .buy-with-card .input-group span {
  border: 2px solid #BDB9BA !important;
  border-right: none !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  color: #8E8D8D;
  font-weight: 400;
  background-color: #fff !important;
  border-right: none !important;
}

.mint .convertion-card .card-body .buy-with-card .input-group .token-id {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  border-left: none !important;
  padding-left: 0%;
  width: 83%;
}

.mint .convertion-card .card-body .input-amount-area .input-group {
  width: 60%;
}

.mint .convertion-card .card-body .input-amount-area .input-group span {
  border: 2px solid #BDB9BA !important;
  border-right: none !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  color: #8E8D8D;
  font-weight: 400;
  background-color: #fff !important;
  border-right: none !important;
}

.mint .convertion-card .card-body .input-amount-area .input-group .token-id {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  border-left: none !important;
  padding-left: 0%;
  width: 83%;
}

.mint .convertion-card .card-body .card-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: #fff;
}

.mint .convertion-card .card-body .token-id {
  border: 2px solid #BDB9BA;
}

.mint .convertion-card .card-body .theme-btn {
  padding: 1rem 3rem;
}

.gallery {
  padding-left: 0% !important;
  padding-right: 0% !important;
}

.gallery .heading {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

.gallery .title {
  font-size: 3rem;
}

.gallery .sub-heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.nav-tabs {
  border-bottom: 1px solid gray !important;
}

.nav-tabs .nav-link {
  background-color: transparent;
  border: none !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  color: #fff;
  opacity: 0.6;
}

.nav-tabs .nav-link:hover {
  color: #fff;
}

.nav-tabs .nav-link.active {
  border-bottom: 3px solid #fff !important;
  background-color: transparent !important;
  color: #fff !important;
  opacity: 1;
  font-weight: 400;
}

.nft-card {
  background: transparent !important;
  border: 1px solid #21ace9 !important;
  border-radius: 0.5rem !important;
  cursor: pointer;
}

.nft-card .card-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.nft-card img {
  width: 90%;
}

.modal .modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal .modal-header {
  border-bottom: none;
}

.modal [type=button]:not(:disabled), .modal [type=reset]:not(:disabled), .modal [type=submit]:not(:disabled), .modal button:not(:disabled) {
  cursor: pointer;
}

.modal .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
  font-size: 2rem;
}

.modal .modal-content {
  background-color: #051368;
  border: 1px solid #21ace9;
}

.success-screen .success .card {
  background-color: transparent;
  border: none;
}

.success-screen .success .card .card-body .card-title {
  color: #fff;
  font-size: 2rem;
}

.success-screen .success .card .card-body a {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  cursor: pointer;
  font-size: 16pt;
  color: #F7276B;
}

.success-screen .success .success-text h1 {
  color: #fff;
  font-size: 3rem;
}

.success-screen .success .success-text p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 26px;
  color: #fff;
  padding-top: 1rem;
  opacity: 0.6;
}

.success-screen .success .success-text .btn {
  border-radius: 0%;
  font-family: "Helvetica";
}

.success-screen .success .success-text .share {
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #212529;
  font-family: "Helvetica";
  padding: 0rem 1.5rem;
}

.success-screen .success .toggle-arrow i {
  cursor: pointer;
  font-size: 1rem;
}

.success-screen .success .toggle-arrow i:nth-child(1) {
  opacity: 0.5;
}

@media (max-width: 990px) {
  .wallet-button {
    width: 100% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .theme-btn {
    padding: 0.8rem 1rem;
  }
  .responsive-image {
    width: 100%;
  }
  .token-id {
    width: 100%;
  }
  .token-id-btn {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px !important;
    margin: 1.75rem auto;
  }
}

@media (min-width: 990px) and (max-width: 1400px) {
  .wallet-button {
    width: 50% !important;
  }
}

.wallet-button {
  width: 33%;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 575px) {
  .container {
    width: 100%;
    padding-right: var(--bs-gutter-x, 1.75rem) !important;
    padding-left: var(--bs-gutter-x, 1.75rem) !important;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1100px !important;
  }
}

@media (min-width: 1080px) and (max-width: 1400px) {
  .input-amount-area .input-group .token-id {
    width: 80% !important;
  }
  .buy-with-card .input-group .token-id {
    width: 80% !important;
  }
}

@media (min-width: 991px) and (max-width: 1079px) {
  .input-amount-area .input-group .token-id {
    width: 77% !important;
  }
  .buy-with-card .input-group .token-id {
    width: 77% !important;
  }
}

@media (min-width: 500px) and (max-width: 990px) {
  .input-amount-area .input-group .token-id {
    width: 80% !important;
  }
}

@media (min-width: 770px) and (max-width: 990px) {
  .buy-with-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .buy-with-card .input-group {
    width: 100% !important;
  }
  .buy-with-card .input-group .token-id {
    width: 91% !important;
  }
  .input-amount-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .input-amount-area .input-group {
    width: 100% !important;
  }
  .input-amount-area .input-group .token-id {
    width: 91% !important;
  }
  .input-amount-area button {
    margin-top: 10px;
  }
}

@media (max-width: 485px) {
  .input-amount-area .input-group .token-id {
    width: 85% !important;
  }
  .buy-with-card .input-group .token-id {
    width: 85% !important;
  }
}

@media (min-width: 300px) and (max-width: 409px) {
  .buy-with-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .buy-with-card .input-group {
    width: 100% !important;
  }
  .buy-with-card .input-group .token-id {
    width: 80% !important;
  }
  .input-amount-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .input-amount-area .input-group {
    width: 100% !important;
  }
  .input-amount-area .input-group .token-id {
    width: 80% !important;
  }
  .input-amount-area button {
    margin-top: 10px;
  }
}

@media (min-width: 410px) and (max-width: 450px) {
  .buy-with-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .buy-with-card .input-group {
    width: 100% !important;
  }
  .buy-with-card .input-group .token-id {
    width: 80% !important;
  }
  .input-amount-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .input-amount-area .input-group {
    width: 100% !important;
  }
  .input-amount-area .input-group .token-id {
    width: 80% !important;
  }
  .input-amount-area button {
    margin-top: 10px;
  }
}

@media (min-width: 450px) and (max-width: 769px) {
  .buy-with-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .buy-with-card .input-group {
    width: 100% !important;
  }
  .buy-with-card .input-group .token-id {
    width: 85% !important;
  }
  .input-amount-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .input-amount-area .input-group {
    width: 100% !important;
  }
  .input-amount-area .input-group .token-id {
    width: 85% !important;
  }
  .input-amount-area button {
    margin-top: 10px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1150px !important;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1280px !important;
  }
}
