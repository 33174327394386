@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Helvetica.ttf');
}

$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Montserrat', sans-serif;
$texture-font: 'Helvetica';
$theme-color: #051368;
$text-color: #fff;
$theme-matching: #F7276B;

@mixin title {
  font-family: $secondary-font;
  font-weight: 700;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


body{
  background: $theme-color !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}
$container-padding-x: '2rem';

// Source mixin
@mixin make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}

// Usage
.custom-container {
  @include make-container();
}

.allow_list_title{
  font-family: $secondary-font;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0%;
}

.allow_list_p{
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 1.5rem;
}

.crossmint_note{
  color: #FFFFFF;
  opacity: 0.5;
  font-weight: 400;
}

a{
  text-decoration: none !important;
}

.pt-5rem{
  padding-top: 4rem;
}

#error{
  color: $theme-matching;
  font-size: 1rem;
  font-family: $primary-font;
  font-weight: 400;
}
#error_msg{
  color: $theme-matching;
  font-size: 1.125rem;
  font-family: $primary-font;
  font-weight: 400;
  margin-bottom: 0%;
}
.title{
  @include title;
}

.learn-more{
  border: 1px solid $text-color;
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: transparent;
  color: $text-color;  
  font-size: 1rem;
  font-weight: 600;
  font-family: $texture-font;
  transition: 0.3s ease;
  &:hover{
    color: $text-color;
    transform: scale(1.1);
  }
}

.model-learn-more{
  border: 1px solid $text-color;
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: transparent;
  color: $text-color;  
  font-size: 1rem;
  font-weight: 600;
  font-family: $texture-font;
  &:hover{
    color: $text-color;
  }
}

.token-id{
  background: $text-color;
  border: 1px solid $theme-matching;
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  width: 40%;
  &:focus{
    outline: none;
  }
}
.token-id-btn{
  width: 58%;
}
.theme-btn{
  background: $theme-matching;
  border-radius: 0.5rem;
  color: $text-color;
  padding: 0.75rem 1.25rem;
  font-family: $texture-font;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  transition: 0.3s ease;
  &:hover{
    color: $text-color;
    transform: scale(1.1);
  }
}
.load-more-btn{
  background: transparent;
  border-radius: 0.5rem;
  color: $theme-matching;
  padding: 0.75rem 1.25rem;
  font-family: $texture-font;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid $theme-matching;
  transition: 0.3s ease;
  &:hover{
    color: $theme-matching;
    transform: scale(1.1);
  }
}

.navbar{
  background: #091d2c;
  .navbar-nav{
    align-items: center;
  }
  .nav-link{
    font-family: $primary-font;
    font-weight: 600;
    color: $text-color !important;
    margin: 0 1rem;
    font-size: 1rem;
    &:hover{
      color: $theme-matching !important;
    }
  }
  .dropdown-menu{
    margin-top: 100px;
    width: 100%;
    background: #081d2c;
    a{
      color: $text-color;
      font-family: $primary-font;
      font-weight: 600;
      &:hover{
        color: $theme-matching;
        background: #081d2c;
      }
    }
  }
  .dropdown-toggle{
    span{
      opacity: 0.6;
    }
    margin: 0%;
    &::after{
      content: none;
    }
}
}

.desc-card{
  padding: 1rem;
  background: #030D49;
  border-radius: 15px;
  h6{
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 0.75rem;
    color: #BDB9BA;
  }
  h4{
    font-family: $secondary-font;
    font-weight: 700;
    color: $text-color;
    font-size: 1rem;
    margin-bottom: 0%;
  }
}

.credits{
  .home-title{
    font-size: 3rem !important;
  }
  .subtitle{
    font-family: $primary-font;
    font-weight: 400;
    // font-size: 1.188rem;
  }
}

.homepage{
  // .lifetime-founders{
  //   h1{
  //     font-size: 2rem;
  //   }
  //   p{
  //     color: $text-color;
  //     font-size: 1.375rem;
  //     font-family: $primary-font;
  //     font-weight: 400;
  //   }
  // }
  .desc-lifetime-founder{
      color: $text-color;
      // font-size: 1.375rem;
      font-family: $primary-font;
      font-weight: 400;
      text-align: justify;
  }
  .opensea{
    color: $text-color;
    text-decoration: underline !important;
    font-family: $primary-font;
    font-weight: 400;
    font-size: 1.375rem;
  }
  .title{
    font-size: 2rem;
    margin-bottom: 0%;
  }
  .remaining{
    font-family: $secondary-font;
    color: $theme-matching;
    font-size: 2.25rem;
    font-weight: 400;
  }
  .total{
    font-size: 1.25rem;
    font-family: $secondary-font;
    color: $text-color;
  }
  .light-text{
    color: #E5E5E5;
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 13px;
  }
  .description{
    font-family: $primary-font;
    font-weight: 400;
    color: $text-color;
    font-size: 1.375rem;
  }
}
.mint{
  .remaining{
    font-family: $secondary-font;
    font-size: 1.75rem;
  }
  .title{
    font-size: 3rem;
  }
  .desc{
    font-family: $primary-font;
    font-weight: 400;
    // font-size: 1.375rem;
  }
  .convertion-card{
    background: transparent;
    border: none;
    .btn{
      background-color: $theme-matching;
      color: $text-color;
      padding: 1rem;
      border: 1px solid #F7276B;
      font-size: 0.95rem;
      font-weight: 600;
      padding: 0.75rem 1.25rem;
      border-radius: 0.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      transition: 0.3s ease;
      &:focus{
        box-shadow: none;
      }
      &:hover{
        transform: scale(1.1)
      }
    }
    .card-body{
      .buy-with-card{
        .input-group{
          width: 50%;
          span{
            border: 2px solid #BDB9BA !important;
            border-right: none !important;
            border-top-left-radius: 0.5rem !important;
            border-bottom-left-radius: 0.5rem !important;
            color: #8E8D8D;
            font-weight: 400;
            background-color: #fff !important;
            border-right: none !important;
          }
          .token-id{
            border-top-right-radius: 0.5rem !important;
            border-bottom-right-radius: 0.5rem !important;
            border-left: none !important;
            padding-left: 0%;
            width: 83%;
          }
        }
      }
      .input-amount-area{
      .input-group{
        width: 60%;
        span{
          border: 2px solid #BDB9BA !important;
          border-right: none !important;
          border-top-left-radius: 0.5rem !important;
          border-bottom-left-radius: 0.5rem !important;
          color: #8E8D8D;
          font-weight: 400;
          background-color: #fff !important;
          border-right: none !important;
        }
        .token-id{
          border-top-right-radius: 0.5rem !important;
          border-bottom-right-radius: 0.5rem !important;
          border-left: none !important;
          padding-left: 0%;
          width: 83%;
        }
      }
    }
      .card-title{
        @include title();
        font-size: 1.5rem;
        color: $text-color;
      }
      .token-id{
        border: 2px solid #BDB9BA;
      }
      .theme-btn{
        padding: 1rem 3rem;
      }
    }
  }
}
.gallery{
  padding-left: 0% !important;
  padding-right: 0% !important;    
  .heading{
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 1.25rem;
  }
  .title{
      font-size: 3rem;
  }
  .sub-heading{
      font-family: $primary-font;
      font-weight: 400;
      // font-size: 1.375rem;
  }
}
.nav-tabs{
  border-bottom: 1px solid gray !important;
  .nav-link{
    background-color: transparent;
    border: none !important;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 1.5rem;
    color: $text-color;
    opacity: 0.6;
    &:hover{
      color: $text-color;
    }
  }
  .nav-link.active{
    border-bottom: 3px solid $text-color !important;
    background-color: transparent !important;
    color: $text-color !important;
    opacity: 1;
    font-weight: 400;
  }
}
.nft-card{
  background: transparent !important;
  border: 1px solid #21ace9 !important;
  border-radius: 0.5rem !important;
  cursor: pointer;
  .card-title{
    font-family: $secondary-font;
    font-weight: 500;
  }
  img{
    width: 90%;
  }
}
.modal{
  
element.style {
}
.modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}
.modal-header{
  border-bottom: none;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.btn-close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
    font-size: 2rem;
}
  .modal-content{
    background-color: $theme-color;
    border: 1px solid #21ace9;
  }
}

.success-screen{    
  .success{
      .card{
          background-color: transparent;
          border: none;
          .card-body{
              .card-title{
                  color: $text-color;
                  font-size: 2rem;
              }
              a{
                  font-family: $primary-font;
                  font-weight: 500;
                  cursor: pointer;
                  font-size: 16pt;
                  color: $theme-matching;
              }
          }
      }
      .success-text{
          h1{
              color: $text-color;
              font-size: 3rem;
          }
          p{
              font-family: $primary-font;
              font-weight: 400;
              font-size: 1.25rem;
              line-height: 26px;
              color: $text-color;
              padding-top: 1rem;
              opacity: 0.6;
          }
          .btn{
              // @include themeButton();
              border-radius: 0%;
              font-family: $texture-font;
          }
          .share{
              font-size: 1rem;
              font-weight: 400;
              color: #212529;
              text-align: center;
              text-decoration: none;
              background-color: transparent;
              border: 1px solid #212529;
              font-family: $texture-font;
              padding: 0rem 1.5rem;
          }
      }
      .toggle-arrow{
          i{
              cursor: pointer;
              font-size: 1rem;
              &:nth-child(1){
                  opacity: 0.5;
              }
          }
      }
  }
}

@media (max-width: 990px){
  .wallet-button{
    width: 100% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }
  .theme-btn{
    padding: 0.8rem 1rem;
  }
  .responsive-image{
    width: 100%;
  }
  .token-id{
    width: 100%;
  }
  .token-id-btn{
    width: 100%;
  }
}
@media (min-width: 990px){
  
  // .container{
  //   margin: 0 200px !important;
  // }
}

@media (min-width: 576px){
.modal-dialog {
    max-width: 600px !important;
    margin: 1.75rem auto;
}
}

@media (min-width: 990px) and ( max-width: 1400px){
  .wallet-button{
    width:50% !important;
  }
}

.wallet-button{
  width:33%;
}



.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 575px) {

  .container {
    width: 100%;
    padding-right: var(--bs-gutter-x, 1.75rem) !important;
    padding-left: var(--bs-gutter-x, 1.75rem) !important;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px !important;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px !important;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1100px !important;
  }
}

@media (min-width: 1080px) and (max-width: 1400px) {
  .input-amount-area{
    .input-group{
      .token-id{
        width: 80% !important;
      }
    }
  }
  .buy-with-card{
    .input-group{
      .token-id{
        width: 80% !important;
      }
    }
  }
}

@media (min-width: 991px) and (max-width: 1079px) {
  .input-amount-area{
    .input-group{
      .token-id{
        width: 77% !important;
      }
    }
  }
  .buy-with-card{
    .input-group{
      .token-id{
        width: 77% !important;
      }
    }
  }
}

@media (min-width: 500px) and (max-width : 990px){
  .input-amount-area{
    .input-group{
      .token-id{
        width: 80% !important;
      }
    }
  }
}

@media (min-width: 770px) and (max-width: 990px) {
  .buy-with-card{
    flex-direction: column;
    .input-group{
      width: 100% !important;
      .token-id{
        width: 91% !important;
      }
    }
  }
  .input-amount-area{
    .input-group{
      width: 100% !important;
      .token-id{
        width: 91% !important;
      }
    }
    flex-direction: column;
    button{
      margin-top: 10px;
    }
  }
}
@media (max-width: 485px) {
  .input-amount-area{
    .input-group{
      .token-id{
        width: 85% !important;
      }
    }
  }
  .buy-with-card{
    .input-group{
      .token-id{
        width: 85% !important;
      }
    }
  }
}
@media (min-width: 300px) and (max-width: 409px) {
  .buy-with-card{
    flex-direction: column;
    .input-group{
      width: 100% !important;
      .token-id{
        width: 80% !important;
      }
    }
  }
  .input-amount-area{
    .input-group{
      width: 100% !important;
      .token-id{
        width: 80% !important;
      }
    }
    flex-direction: column;
    button{
      margin-top: 10px;
    }
  }
}
@media (min-width: 410px) and(max-width: 450px) {
  .buy-with-card{
    flex-direction: column;
    .input-group{
      width: 100% !important;
      .token-id{
        width: 80% !important;
      }
    }
  }
  .input-amount-area{
    .input-group{
      width: 100% !important;
      .token-id{
        width: 80% !important;
      }
    }
    flex-direction: column;
    button{
      margin-top: 10px;
    }
  }
}
@media (min-width: 450px)and (max-width: 769px) {
  .buy-with-card{
    flex-direction: column;
    .input-group{
      width: 100% !important;
      .token-id{
        width: 85% !important;
      }
    }
  }
  .input-amount-area{
    .input-group{
      width: 100% !important;
      .token-id{
        width: 85% !important;
      }
    }
    flex-direction: column;
    button{
      margin-top: 10px;
    }
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1150px !important;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1280px !important;
  }
}